import API from "@/helpers/Api";

export default class PermissionRepository extends API {


    orderByOptions= [
        { text: 'id', value: 'id', name: 'perm.id' },
        { text: 'role', value: 'role', name: 'g.id' },
        { text: 'navigation', value: 'icon', name: 'nav.navigation' },
        { text: 'permission', value: 'url', name: 'perm.permission' },
    ];

    create(data){
        const permissions = this.post('/v2/permissions', data)
        return permissions;
    }
    edit(data, id){
        const permissions = this.patch('/v2/permissions/'+id, data)
        return permissions;
    }
    find(id){
        const permissions = this.findOne('/v2/permissions/'+id)
        return permissions;
    }
    supprimer(id){
        const permissions = this.delete('/v2/permissions/'+id)
        return permissions;
    }
    search(fields, orderBy, sortOrder, itemPerPage, currentPage)
    {

        const permissions = this.listing('/v2/permissions', fields, orderBy, sortOrder, itemPerPage, currentPage)
        //console.log(permissions);
        return permissions;
    }


}