<template>
  <!-- Begin page -->
  <html v-bind="htmlAttrs">

  <div id="layout-wrapper" >

    <HeaderComponent/>

    <!-- removeNotificationModal -->
    <div id="removeNotificationModal" class="modal fade zoomIn" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="NotificationModalbtn-close"></button>
          </div>
          <div class="modal-body">
            <div class="mt-2 text-center">
              <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you sure ?</h4>
                <p class="text-muted mx-4 mb-0">Are you sure you want to remove this Notification ?</p>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn w-sm btn-danger" id="delete-notification">Yes, Delete It!</button>
            </div>
          </div>

        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    <!-- ========== App Menu ========== -->
    <AppMenu/>
    <!-- Left Sidebar End -->
    <!-- Vertical Overlay-->
    <div class="vertical-overlay"></div>

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="main-content">

      <div class="page-content">
        <div class="container-fluid">
          <router-view/>

        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->

      <footer class="footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-6">
              2023 © KLISPAY.
            </div>
            <div class="col-sm-6">
              <div class="text-sm-end d-none d-sm-block">
                Réaliser & Développé par NTA Technologie
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->
  </html>






</template>

<script>

import HeaderComponent from "@/components/Header";
import AppMenu from "@/components/AppMenu";
export default {
  name: 'LayoutPage',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'KLISPAY',
    // all titles will be injected into this template
  },

  data() {
    return {
      user: null,
      userPermissions: null,
      selectedCollectivite: null,
      navbarMenuHTML:null,
      horizontalMenuSplit: 7,
      htmlAttrs: {
        lang: 'en',
        'data-layout': 'vertical',
        'data-toolbar': 'light',
        'data-sidebar': 'dark',
        'data-sidebar-size': 'lg',
        'data-sidebar-image': 'none',
        'data-preloader': 'disable'
      },

    }

  },
  methods: {


  },
  mounted() {
    if (sessionStorage.getItem('defaultAttribute')) {

      var attributesValue = document.documentElement.attributes;
      var CurrentLayoutAttributes = {};
      Object.entries(attributesValue).forEach(function(key) {
        if (key[1] && key[1].nodeName && key[1].nodeName != "undefined") {
          var nodeKey = key[1].nodeName;
          CurrentLayoutAttributes[nodeKey] = key[1].nodeValue;
        }
      });
      if(sessionStorage.getItem('defaultAttribute') !== JSON.stringify(CurrentLayoutAttributes)) {
        sessionStorage.clear();
        window.location.reload();
      } else {
        var isLayoutAttributes = {};
        isLayoutAttributes['data-layout'] = sessionStorage.getItem('data-layout');
        isLayoutAttributes['data-sidebar-size'] = sessionStorage.getItem('data-sidebar-size');
        isLayoutAttributes['data-layout-mode'] = sessionStorage.getItem('data-layout-mode');
        isLayoutAttributes['data-layout-width'] = sessionStorage.getItem('data-layout-width');
        isLayoutAttributes['data-sidebar'] = sessionStorage.getItem('data-sidebar');
        isLayoutAttributes['data-sidebar-image'] = sessionStorage.getItem('data-sidebar-image');
        isLayoutAttributes['data-layout-direction'] = sessionStorage.getItem('data-layout-direction');
        isLayoutAttributes['data-layout-position'] = sessionStorage.getItem('data-layout-position');
        isLayoutAttributes['data-layout-style'] = sessionStorage.getItem('data-layout-style');
        isLayoutAttributes['data-topbar'] = sessionStorage.getItem('data-topbar');
        isLayoutAttributes['data-preloader'] = sessionStorage.getItem('data-preloader');
        isLayoutAttributes['data-body-image'] = sessionStorage.getItem('data-body-image');

        Object.keys(isLayoutAttributes).forEach(function (x) {
          if (isLayoutAttributes[x] && isLayoutAttributes[x]) {
            document.documentElement.setAttribute(x, isLayoutAttributes[x]);
          }
        });
      }
    }


  },

  components: {

    AppMenu,
    HeaderComponent
  },
  props: {
    msg: String
  },
  created() {
    document.title = "KLISPAY";
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
