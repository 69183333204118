import API from "@/helpers/Api";

export default class User {

    id;
    nom;
    prenom;
    fullName;
    username;
    entites;
    groupe
    ramifications;
    type = 3;
    message;
    email;
    password;
    roles;
    enabled = false;
    api = new API();

    constructor() {
        this.id = null;
        this.nom = null;
        this.enabled = false;
        this.username = null;
        this.ramifications = [];
        this.groupe = null;
        this.email = null;
        this.password = null;
        this.passwordConfirm = null;
        this.roles = [];
        this.fullName = null;
        this.message = null;
        this.entites = null;
        this.type = 3;
    }


  
     

}