import { createStore } from 'vuex'
import User from "@/entity/User";

export default createStore({
  state: {
    user: new User(),
    selectedCollectivite: null,
    selectedAnnee: null,
    selectedRamification: null,
    userPermissions: null
  },
  getters: {
    user: state => state.user,
    selectedCollectivite: state => state.selectedCollectivite,
    selectedRamification: state => state.selectedRamification,
    userPermissions: state => state.userPermissions,
    selectedAnnee: state => state.selectedAnnee

  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_SELECTED_ANNEE(state, annee) {
      state.selectedAnnee = annee
    },
    SET_SELECTED_RAMIFICATION(state, ramification) {
      state.selectedRamification = ramification
    },
    SET_SELECTED_COLLECTIVITE(state, selected){
      state.selectedCollectivite = selected;

    },
    SET_USER_PERMISSIONS(state, selected){
      state.userPermissions = selected;

    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    setAnnee({ commit }, annee) {
      commit('SET_SELECTED_ANNEE', annee)
    },
    setUserPermissions({ commit }, selected) {
      commit('SET_USER_PERMISSIONS', selected)
    },
    setCollectivite({ commit }, selected) {
      commit('SET_SELECTED_COLLECTIVITE', selected)
    },
    setRamification({ commit }, selected) {
      commit('SET_SELECTED_RAMIFICATION', selected)
    }
  },
  modules: {
  }
})
