<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :on-cancel="onCancel"
           :is-full-page="fullPage"/>
  <html lang="en" data-layout="vertical" data-topbar="light" data-sidebar="dark" data-sidebar-size="lg" data-sidebar-image="none" data-preloader="disable">
  <div class="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
    <div class="bg-overlay"></div>
    <!-- auth-page content -->
    <div class="auth-page-content overflow-hidden pt-lg-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="p-lg-5 p-4 auth-one-bg h-100">
                    <div class="bg-overlay"></div>
                    <div class="position-relative h-100 d-flex flex-column">
                      <div class="mb-4">
                        <a href="index.html" class="d-block">
                          <img src="../assets/images/logo.svg" alt="" height="100" style="color: #fff !important;" >
                        </a>
                      </div>
                      <div class="">
                        <div class="mb-3">
                          <i class="ri-double-quotes-l display-4 text-success"></i>
                        </div>

                        <div  id="qoutescarouselIndicators" class="carousel slide" data-bs-ride="carousel">
                          <div style="margin-top: 0px !important;" class="carousel-indicators">
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                          </div>
                          <div class="carousel-inner text-center text-white-50 pb-5">
                            <div class="carousel-item active">
                              <p class="fs-15 fst-italic">" KLISPAY ! Un produit de la Nouvelle Technologie Africaine !"</p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">" Simplifiez vos démarches administratives en payant vos taxes en ligne !"</p>
                            </div>
                            <div class="carousel-item">
                              <p class="fs-15 fst-italic">" En payant vos taxes en ligne, vous contribuez à une répartition plus équitable des charges fiscales ! "</p>
                            </div>
                          </div>
                        </div>
                        <!-- end carousel -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="p-lg-5 p-4">
                    <div>
                      <h5 class="text-primary">Bienvenue sur KLISPAY !</h5>
                      <p class="text-muted">Connectez-vous pour continuer !</p>
                    </div>

                    <div class="mt-4">
                      <form @submit.prevent="login">

                        <div class="mb-3">
                          <div class="form-group">
                            <label style="text-align: left !important; margin: 0px !important; display: flex; justify-content: left" for="username" class="form-label">Pseudo ou Adresse Email</label>
                            <input v-model="utilisateur.email" type="text" class="form-control" id="username" placeholder="Entrez votre pseudo ou adresse email">

                          </div>
                        </div>

                        <div class="mb-3">
                          <div class="float-end">
                            <a href="auth-pass-reset-cover.html" class="text-muted">Mot de passe oublié ?</a>
                          </div>
                          <label style="text-align: left !important; margin: 0px !important; display: flex; justify-content: left"  class="form-label" for="password-input">Mot de passe</label>
                          <div class="position-relative auth-pass-inputgroup mb-3">
                            <input v-model="utilisateur.password" type="password" class="form-control pe-5 password-input" placeholder="Entez votre mot de passe" id="password-input">
                            <button class="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon"><i class="ri-eye-fill align-middle"></i></button>
                          </div>
                        </div>
                        <br>
                        <div class="mt-4">
                          <button class="btn btn-success w-100" type="submit">Connexion</button>
                        </div>



                      </form>
                    </div>
                    <br>
                    <br>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->

        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end auth page content -->

    <!-- footer -->
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="mb-0">&copy;
               2023 Velzon. Crafted with <i class="mdi mdi-heart text-danger"></i> by Themesbrand
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- end Footer -->
  </div>

  </html>
</template>
<script>
import User from "@/entity/User";
import Repository from "@/repository/UserRepository";
import PermRepository from "@/repository/PermissionRepository";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Swal from "sweetalert2"
export default {
  data(){
    return {
      isLoading: false,
      fullPage: true,
      utilisateur: new User(),
      repository: new Repository(),
      permRepository: new PermRepository(),
      userPermissions: null
    }
  },
  methods: {
    initializeToast(icon, message){
      let Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      });
      Toast.fire({
        icon: icon,
        title: message
      })

    },

    login(){
      this.isLoading = true;
      this.repository.check(this.utilisateur.email, this.utilisateur.password)
          .then(response => {
            this.utilisateur = response;
           // console.log(this.utilisateur);

            if (this.utilisateur.message != null) {
              this.isLoading = false;

              this.initializeToast('error', this.utilisateur.message)
            }
            else {
              if (this.utilisateur.enabled === false){
                this.isLoading = false;

                this.initializeToast('error', "Ce utilisateur est inactif, Veuillez contactez l'administrateur !");
              }else{
                if (this.utilisateur.type.nom === 'Utilisateur'){
                  localStorage.setItem('isAuthenticated', true);
                  localStorage.setItem('user', JSON.stringify(this.utilisateur));
                  this.$store.dispatch("setUser", this.utilisateur);

                  this.permRepository.search([{ colonne: 'g.id', operator: 'eq', value: this.utilisateur.groupe.id }], 'perm.id', 'asc', 300, 1)
                      .then(response => {
                        this.userPermissions = response.data
                        localStorage.setItem('userPermissions', JSON.stringify(this.userPermissions));
                        this.$store.dispatch("setUserPermissions", this.userPermissions);

                        //console.log(this.userPermissions);

                      })


                  // this.$store.commit('updateMessage', 'new message')
                  //console.log(localStorage.getItem('isAuthenticated'));

                  // console.log(JSON.parse(localStorage.getItem('collectivites')));
                  //  location.reload();
                  this.isLoading = false;
                  this.$router.push('/');
                }else{
                  this.isLoading = false;
                  this.initializeToast('error', "Vous n'avez pas accès à cet outil");
                }


              }
            }
          });
    }
  },
  name: 'loginPage',
  mounted() {
   // console.log(localStorage.getItem('isAuthenticated'))
    Array.from(document.querySelectorAll("form .auth-pass-inputgroup")).forEach(function(e){Array.from(e.querySelectorAll(".password-addon")).forEach(function(r){r.addEventListener("click",function(){var o=e.querySelector(".password-input");"password"===o.type?o.type="text":o.type="password"})})});
  },
  components:{
    Loading
  }
}
</script>